import React, { useState } from 'react';
import {FormControl} from 'baseui/form-control';
import {Select, TYPE} from 'baseui/select';
import { Label2 } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Alert } from 'baseui/icon';

const BoardSelector = props => {
    const {
      label, 
      boards, 
      onChange, 
      onBlur, 
      value,
      touched,
      error,
      ...opts 
    } = props;
    
    const handleChange = value => {
      console.log("boards: ", value)
      onChange(value);
    }

    const handleBlur = e => {
      onBlur(e);
    }

    const getLabel = ({option}) => (
      <Label2>{option.name}</Label2>
    )

    return (
      <FormControl 
          label={label}
          error={
            touched && error
            ? error
            : null
          }>
        <Select
          {...opts}
          id="compatibleBoards"
          value={value}
          onChange={({value}) => handleChange(value)}
          error={touched && error}
          backspaceClearsInputValue={true}
          options={boards}
          onBlur={handleBlur}
          labelKey="name"
          valueKey="fqbn"
          placeholder="Search"
          maxDropdownHeight="300px"
          type={TYPE.search}
          getOptionLabel={getLabel}
          multi
        />
      </FormControl>
    );
  }

export default BoardSelector;