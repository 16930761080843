import React, { useState, useEffect } from "react";
import { useStyletron } from "styletron-react";
import { createTheme } from 'baseui';
import { ListItem, ListItemLabel } from 'baseui/list';
import Check from 'baseui/icon/check';
import CreatorView from '../views/CreatorView';
import { getBoards, getLibraries, createBoard } from '../utils/clientApi';

import './_create.scss';

const primitives = {
    primaryFontFamily: 'Work Sans',
};

const theme = createTheme(primitives);



const BlueCheck = () => {
    return (
        <Check color="#1F70E9" size={32} />
    )
}

const CreatePage = () => {
    const [engine, setEngine] = useState(null);
    const [boards, setBoards] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const [libInput, setLibInput] = useState("");

    const [css] = useStyletron();

    const ONBOARD_DESC = [
        {
            label: "Get started quickly",
            description: "It only takes around 2 minutes to get your code on Surfboard."
        },
        { 
            label: "Support any project",
            description: "Smart homes, robotic arms, and more--all welcome."
        },
        {
            label: "Contribute to a community of builders",
            description: "Builders can share their work and help others' at the same time."
        }
    ]

    useEffect(() => {
        let isSubscribed = true;

        const handleBoards = async () => {
            if (isSubscribed) {
                const boards = await getBoards();
                setBoards(boards);
            }
        };

        handleBoards();

        return () => isSubscribed = false;

    }, []);

    useEffect(() => {
        let isSubscribed = true;

        const handleLibraries = async () => {
            if (isSubscribed) {
                const libs = await getLibraries(libInput, 100, 0, "name", false, false);
                setLibraries(libs.data);
            }
        };

        handleLibraries();

        return () => isSubscribed = false;

    }, [libInput]);

    useEffect(() => {
        import('styletron-engine-atomic').then(styletron => {
            const clientEngine = new styletron.Client();
            setEngine(clientEngine);
        })
    }, []);

    const handleSubmit = async values => {
        
        if (typeof window.gtag === 'function') {
            window.gtag("event", "select_content", { content_type: "Create" });
        }
        
        const req = {};
        req.email = values.email;
        req.compatibleBoards = values.compatibleBoards;
        req.libs = values.libs.map(lib => lib.details);
        req.files = values.code;

        try {
            const response = await createBoard(req);
            return response.appId;

        } catch (err) {
            throw err;
        }

        
    }

    if (!engine) return null;

    return (
        <div className="onboard-layout-wrapper">
            <div className="section-onboard">
                <div className="onboard-headline">
                    <div className="onboard-text">
                    <ul
                        className={css({
                            paddingLeft: 0,
                            paddingRight: 0,
                        })}
                    >
                        <ListItem 
                            artwork={BlueCheck}
                            overrides={{
                                Content: {
                                    style: ({ $theme }) => {
                                    return {
                                        marginTop: `${theme.sizing.scale600}`,
                                        marginBottom: `${theme.sizing.scale600}`,
                                        backgroundColor: `transparent`
                                    };
                                    }
                                },
                                Root: {
                                    style: ({ $theme }) => ({
                                        backgroundColor: 'transparent'
                                    })
                                    }
                                }}>
                            <ListItemLabel description={ONBOARD_DESC[0].description}>
                                {ONBOARD_DESC[0].label}
                            </ListItemLabel>
                        </ListItem>
                        <ListItem 
                            artwork={BlueCheck}
                            overrides={{
                                Content: {
                                    style: ({ $theme }) => {
                                    return {
                                        marginTop: `${theme.sizing.scale600}`,
                                        marginBottom: `${theme.sizing.scale600}`,
                                        backgroundColor: `transparent`
                                    };
                                    }
                                },
                                Root: {
                                    style: ({ $theme }) => ({
                                        backgroundColor: 'transparent'
                                    })
                                    }
                                }}>
                            <ListItemLabel description={ONBOARD_DESC[1].description}>
                                {ONBOARD_DESC[1].label}
                            </ListItemLabel>
                        </ListItem>
                        <ListItem 
                            artwork={BlueCheck}
                            overrides={{
                                Content: {
                                    style: ({ $theme }) => {
                                    return {
                                        marginTop: `${theme.sizing.scale600}`,
                                        marginBottom: `${theme.sizing.scale600}`,
                                        backgroundColor: `transparent`
                                    };
                                    }
                                },
                                Root: {
                                    style: ({ $theme }) => ({
                                        backgroundColor: 'transparent'
                                    })
                                    }
                                }}>
                            <ListItemLabel description={ONBOARD_DESC[2].description}>
                                {ONBOARD_DESC[2].label}
                            </ListItemLabel>
                        </ListItem>
                    </ul>
                    </div>
                </div>
            </div>
            <div className="onboard-create">
                <div className="onboard-title">
                    <h2 className="onboard-title-text">Create your Surfboard</h2>
                </div>
                <div className="onboard-details row">
                    <CreatorView 
                        onLibInput={value => setLibInput(value)}
                        libraries={libraries}
                        boards={boards}
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreatePage
