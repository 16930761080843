import React from 'react';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import { FormControl } from 'baseui/form-control';

const RadioUpload = props => {
    const { 
        onChange, 
        onBlur, 
        value, 
        label,
        touched,
        error,
        name
    } = props;

    const handleChange = e => {
        e.preventDefault();
        onChange(e.currentTarget.value);
    }

    const handleBlur = e => {
        onBlur(e);
    }

    const errorMessage = () => {
        if (!value && touched) {
            return "Please select an option";
        } else if (error) {
            return error;
        } else {
            return null;
        }
    }

    return (
        <FormControl 
            label={label}
            error={
                errorMessage
            }   
        >
            <RadioGroup
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                align={ALIGN.horizontal}
                error={!errorMessage}
                name={name}
                required
            >
                <Radio value="file">File upload</Radio>
                <Radio
                    value="link"
                >
                    URL
                </Radio>
            </RadioGroup>
        </FormControl>
    )
}

export default RadioUpload;
