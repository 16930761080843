import React, { useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { Select, TYPE } from 'baseui/select';
import { Label2, Paragraph3 } from 'baseui/typography';

const extractLibs = libs => libs.map(lib => {
        return {
            name: lib.name,
            details: {
                name: lib.name,
                author: lib.author,
                version: lib.version,
                url: lib.urls[0].url,
            }
        }
    });

const debounce = (fn, delay) => {
    let timeoutId;
    const debounced = (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    }
    return debounced;
}

const LibSelector = props => {
    const { 
        label,
        libs, 
        onChange, 
        onBlur, 
        onInputChange, 
        value, 
        touched,
        error,
        ...opts 
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    
    const handleChange = value => {
        console.log("value: ", value);
        onChange(value);
    }

    const handleBlur = e => {
      onBlur(e);
    }

    const handleInputChange = debounce(function(term) {
            if (!term) {
                onInputChange(term);
                return;
            }
            setIsLoading(true);
            setTimeout(() => {
                onInputChange(term);
                setIsLoading(false);
            }, 800);
        }, 400);

    const getLabel = ({option}) => {
        return (
            <React.Fragment>
                <Label2>{option.details.name}</Label2>
                <Paragraph3>{option.details.author}</Paragraph3>
                <Paragraph3>{option.details.version}</Paragraph3>
            </React.Fragment>
        );
    };

    const getValueLabel = ({option}) => {
        return option.details.name;
    }

    return (
      <FormControl 
        label={label}
        error={
            error
            ? error
            : null
        }
      >
        <Select
            {...opts}
            id={opts.name}
            value={value}
            onChange={({value}) => handleChange(value)}
            isLoading={isLoading}
            backspaceClearsInputValue={true}
            onInputChange={event => {
                const target = event.target;  
                handleInputChange(target.value);
            }}
            options={extractLibs(libs)}
            onBlur={handleBlur}
            labelKey="details"
            valueKey="name"
            placeholder="Search"
            maxDropdownHeight="300px"
            type={TYPE.search}
            multi
            getOptionLabel={getLabel}
            getValueLabel={getValueLabel}
        />
      </FormControl>
    );
}

export default LibSelector;