import React from 'react';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';

const WrappedInput = props => {
    const { 
      label, 
      boards, 
      onChange, 
      onBlur, 
      value,
      touched,
      error,
      ...opts } = props;
    
    const handleChange = ({target: {value}}) => {
        onChange(value);
    }

    const handleBlur = e => {
        onBlur(e);
    }

    return (
      <FormControl
          label={label}
          error={
              touched && error
                  ? error
                  : null
          }
      >
        <Input
          {...opts}
          id="email-input-id"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched && error}
          type="email"
          required
        />
      </FormControl>
    );
}

export default WrappedInput;