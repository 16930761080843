import React, { useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { getCustom } from '../../utils/clientApi';
import { Input } from 'baseui/input';
import { Button, SIZE, SHAPE } from 'baseui/button';
import { useStyletron } from 'baseui';

function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const WrappedInputButton = props => {
    const {
      isPrimary,
      label, 
      boards, 
      onChange,
      onBlur, 
      value,
      touched,
      error,
      ...opts } = props;
    
    const [css] = useStyletron();
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [fetchError, setFetchError] = useState(error);
    
    const handleChange = ({target: {value}}) => {
      if (isValidHttpUrl(value)) {
        setFetchError("");
      } else {
        setFetchError("Please provide a valid URL."); 
      }

      setInputValue(value);
    }

    const handleClick = async () => {
      if (inputValue && isValidHttpUrl(inputValue)) {
        setIsLoading(true);

        try {
          const content = await getCustom(inputValue);
          onChange({
            content: content,
            name: inputValue.split('/').pop(),
            primary: isPrimary
          });
          setIsLoading(false);

        } catch (err) {
          setFetchError(err.message);
          setIsLoading(false);
        }
      } else {
        setFetchError("Please provide a valid URL.")
      }
    }

    const handleBlur = e => {
        onBlur(e);
    }

    return (
      <FormControl
        label={label}
        error={
            touched && fetchError
                ? fetchError
                : null
        }
      >
        <div className={css({display: 'flex'})}>
          <Input
            {...opts}
            id={opts.name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched && fetchError}
            type="url"
            required
          />
          <Button
            type="button"
            onClick={handleClick}
            isLoading={isLoading}
          >
            Upload
          </Button>
        </div>
      </FormControl>
    );
}

WrappedInputButton.defaultProps = {
  isPrimary: false
}

export default WrappedInputButton;