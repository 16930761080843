import axios from 'axios';

const duinoInstance = axios.create({
    baseURL: "https://compiler.sfbd.is",
    timeout: 10000
})

const appInstance = axios.create({
    baseURL: "https://app.sfbd.is",
    timeout: 10000
})

/**
 * GET call to URL provided that returns the code to the embed.
 */
export const getBoards = async () => {
    return await duinoInstance.get(`/v3/info/boards`)
        .then((response) => {
            return response.data;
        })
};

/**
 * GET call to URL provided that returns the code to the embed.
 */
export const getLibraries = async (search, limit, skip, sortBy, sortDesc, exact) => {
    const params = {
            search: search,
            limit: limit,
            skip: skip,
            sortBy: sortBy,
            sortDesc: sortDesc,
            exact: exact,
        };

    return await duinoInstance.get(`/v3/info/libraries`, { params: params })
        .then((response) => {
            return response.data;
        })
    
};

export const getCustom = async (url) => {
    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error(error);
            if (error.response) {
                if (error.response.status === 400) {
                    throw new Error("Destination server could not process the request. Please provide a different link.");
                } else if (error.response.status === 500) {
                    throw new Error("Destination server encountered an error while processing the request.");
                } else {
                    throw new Error("Destination server could not process the request. Please provide a different link.");
                }
            } else if (error.request) {
                throw new Error("Destination server could not be reached. If problems persist, please contact Surfboard.");
            } else {
                throw new Error("Request could not be made. Please check that you provided a valid URL.");
            }
        });
};

export const createBoard = async (req) => {
    return await appInstance.post("/onboard/create", req)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error(error);
            if (error.response) {
                if (error.response.status === 400) {
                    throw new Error("Surfboard could not process the request. Please fill all the required fields.");
                } else if (error.response.status === 500) {
                    throw new Error("Surfboard encountered an error while processing the request.");
                } else {
                    throw new Error("Surfboard could not process the request. If problems persist, please contact Surfboard.");
                }
            } else if (error.request) {
                throw new Error("Surfboard could not be reached. If problems persist, please contact Surfboard.");
            } else {
                throw new Error("Request could not be made. Please check that you provided valid entries.");
            }
        });
}