import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Button, SHAPE, KIND, SIZE } from 'baseui/button';
import { ProgressSteps, NumberedStep } from 'baseui/progress-steps';
import { Tag } from 'baseui/tag';
import { FormControl } from 'baseui/form-control';

import { CodeSnippet } from 'carbon-components-react';

import { Formik, useFormikContext } from "formik";
import * as Yup from 'yup';

import BoardSelector from "../../components/BoardSelector";
import LibSelector from "../../components/LibSelector";
import WrappedInput from '../../components/WrappedInput';
import WrappedInputButton from '../../components/WrappedInputButton';
import RadioUpload from '../../components/RadioUpload';
import SketchUploader from '../../components/SketchUploader';

const validationSchema = Yup.object().shape({
    method: Yup.string()
        .matches(/(link|file)/, "Please choose an option."),
    code: Yup.array()
        .of(Yup.object().shape({
            content: Yup.string("Please upload an .ino file."),
            name: Yup.string()
                .required("Please provide a sketch name."),
            primary: Yup.boolean()
        }))
        .min(1, "Please provide a sketch."),

    compatibleBoards: Yup.array()
        .min(1, "Please select at least one compatible board.")
        .required("Please select at least one compatible board."),

    libs: Yup.array()
        .of(Yup.object({
            name: Yup.string(),
            author: Yup.string(),
            version: Yup.string(),
            url: Yup.string()
                .url(),
        })),
    email: Yup.string()
        .email("Please provide a valid email address.")
        .required("Please provide an email address."),
    });

function ValidatedButton(props) {
    const { touched, errors, values } = useFormikContext();
    const { children, field, onValidate, isValidate } = props;

    const isDisabled = errors[field] || !values[field] ? true : false;

    const handleClick = () => {
        console.log("values: ", values);
        console.log("touched: ", touched);
        if (isValidate) {

            console.log("errors: ", errors);
            if (!errors[field]) {
                onValidate();
            }

        } else {
            onValidate();
        }
    }

    return (
        <Button
        children={children}
        shape={SHAPE.pill}
        kind={KIND.secondary}
        size={SIZE.compact}
        disabled={isValidate ? isDisabled : false}
        overrides={{
            BaseButton: {
            style: ({$theme}) => ({
                marginLeft: $theme.sizing.scale200,
                marginRight: $theme.sizing.scale200,
                marginTop: $theme.sizing.scale800,
            }),
            },
        }}
        type="button"
        onClick={handleClick}
        />
    );
};

const CreatorView = props => {
    const { onLibInput, libraries, boards, onSubmit } = props;
    const [current, setCurrent] = useState(0);
    const [submitError, setSubmitError] = useState("");
    const [success, setSuccess] = useState(false);
    const [appId, setAppId] = useState("");

    const APP_URL = `https://app.sfbd.is/app/${appId}`;

    const APP_HTML = (
        `<iframe src="https://app.sfbd.is/app/${appId}"
        style={{width:'500px', height:'720px', border:'3px solid #111111', backgroundColor: '#FFFFFF'}}
        allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking; serial"
        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>`
    );

    const termsSentence = () => {
        return (
            <p className="click-notice">By clicking the 'Create board' button, you are indicating that you accept our <Link className="terms-link" to="/terms">Terms of Service</Link> and <Link className="main-privacy-link" to="/privacy">Privacy Policy</Link>.</p>
        )
    }

    return (
        <Formik
            initialValues={{
                method: '',
                code: [],
                compatibleBoards: [],
                libs: [],
                email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                setSubmitError("");
                try {
                    const appId = await onSubmit(values);
                    setAppId(appId);
                } catch (err) {
                    setSubmitError(err.message);
                    return;
                }
                setSuccess(true);
                actions.resetForm();
            }}
        >
            {({
                values,
                touched,
                errors,
                isSubmitting,
                handleBlur,
                handleSubmit,
                validateField,
                setFieldValue,
                setFieldTouched
            }) => (
                <form className="form-board" onSubmit={handleSubmit}>
                    <ProgressSteps
                        current={current}
                    >
                        <NumberedStep title="Choose upload method*">
                            <RadioUpload
                                onChange={value => setFieldValue("method", value)}
                                onBlur={e => {
                                    handleBlur(e);
                                    validateField("method");
                                }}
                                value={values.method}
                                touched={touched.method}
                                error={errors.method}   
                                name="method"
                            />
                            <ValidatedButton size="compact" field="method" isValidate={true} onValidate={() => setCurrent(1)}>
                                Next
                            </ValidatedButton>
                        </NumberedStep>
                        <NumberedStep title="Upload primary sketch*">
                            {values.method === "link" ? (
                                <WrappedInputButton
                                    isPrimary={true}
                                    onChange={value => {
                                        setFieldValue("code", [...values.code, value]);
                                    }}
                                    onBlur={handleBlur}
                                    touched={touched.code}
                                    error={errors.code}
                                    name="code"
                                />
                             ) : null
                            }
                            {values.method === "file" ? (
                                <SketchUploader
                                    isPrimary={true}
                                    accept=".ino"
                                    isMultiple={false}
                                    onUpload={file => {
                                        setFieldValue("code", [...values.code, file]);
                                        setFieldTouched("code");
                                }}
                                    onBlur={handleBlur}
                                    touched={touched.code}
                                    error={errors.code}
                                    name="code"

                                />                        
                                ): null
                            }
                            {values.code.length > 0
                                ? (values.code.map(file =>
                                    <Tag 
                                        key={file.name}
                                        children={file.name} 
                                        onActionClick={() => {
                                            setFieldValue("code", values.code.filter(deleteFile => 
                                                deleteFile.filename !== file.filename
                                            ));
                                        }}
                                    />)
                                )
                                : null
                            }
                            <ValidatedButton size="compact" field="code" isValidate={false} onValidate={() => setCurrent(0)}>
                            Previous
                            </ValidatedButton>
                            <ValidatedButton size="compact" field="code" isValidate={true} onValidate={() => setCurrent(2)}>
                            Next
                            </ValidatedButton>
                        </NumberedStep>
                        <NumberedStep title="Upload additional files">
                            {values.method === "link" ? (
                                <WrappedInputButton
                                    onChange={value => {
                                        setFieldValue("code", [...values.code, value]);
                                    }}
                                    onBlur={handleBlur}
                                    touched={touched.code}
                                    name="code"
                                />
                                ) : null
                            }
                            {values.method === "file" ? (
                                <SketchUploader
                                    isMultiple={true}
                                    accept=".ino, .c, .cpp, .h"
                                    onUpload={file => {
                                        setFieldValue("code", [...values.code, file]);
                                        setFieldTouched("code");
                                    }}
                                    onBlur={handleBlur}
                                    touched={touched.code}
                                    name="code"
                                />                      
                                ): null
                            }
                            {values.code.length > 0
                                ? (values.code.map(file =>
                                    <Tag 
                                        key={file.name}
                                        children={file.name} 
                                        onActionClick={() => {
                                            setFieldValue("code", values.code.filter(deleteFile => 
                                                deleteFile.filename !== file.filename
                                            ));
                                        }}
                                    />)
                                )
                                : null
                            }
                            <ValidatedButton size="compact" field="code" isValidate={false} onValidate={() => setCurrent(1)}>
                                Previous
                            </ValidatedButton>
                            <ValidatedButton size="compact" field="code" isValidate={true} onValidate={() => setCurrent(3)}>
                                Next
                            </ValidatedButton>
                        </NumberedStep>
                        <NumberedStep title="Include libraries">
                            <LibSelector
                                libs={libraries}
                                onInputChange={onLibInput}
                                onChange={value => setFieldValue("libs", value)}
                                onBlur={handleBlur}
                                value={values.libs}
                                touched={touched.libs}
                                error={errors.libs?.url}
                                name="libs"
                            />
                            <ValidatedButton size="compact" field="libs" isValidate={false} onValidate={() => setCurrent(2)}>
                                Previous
                            </ValidatedButton>
                            <ValidatedButton size="compact" field="libs" isValidate={true} onValidate={() => setCurrent(4)}>
                                Next
                            </ValidatedButton>
                        </NumberedStep>
                        <NumberedStep title="Select compatible boards*">
                            <BoardSelector
                                boards={boards} 
                                onChange={value => setFieldValue("compatibleBoards", value)}
                                onBlur={handleBlur}
                                value={values.compatibleBoards}
                                touched={touched.compatibleBoards}
                                error={errors.compatibleBoards}
                                name="compatibleBoards"
                            />
                            <ValidatedButton size="compact" field="compatibleBoards" isValidate={false} onValidate={() => setCurrent(3)}>
                                Previous
                            </ValidatedButton>
                            <ValidatedButton size="compact" field="compatibleBoards" isValidate={true} onValidate={() => setCurrent(5)}>
                                Next
                            </ValidatedButton>
                        </NumberedStep>
                        <NumberedStep title="Enter your email address*">
                            <WrappedInput
                                onChange={value => setFieldValue("email", value)}
                                onBlur={handleBlur}
                                value={values.email}
                                touched={touched.email}
                                error={errors.email}
                                name="email"
                            />
                            <FormControl
                                error={
                                    submitError
                                    ? submitError
                                    : null
                                }
                                caption={
                                    success && !isSubmitting
                                    ? "Successfully created!"
                                    : termsSentence
                                }
                            
                            >
                                <Button 
                                    type="submit" 
                                    isLoading={isSubmitting}
                                >
                                    Create board
                                </Button>
                            </FormControl>
                            <ValidatedButton size="compact" field="email" isValidate={false} onValidate={() => setCurrent(4)}>
                                Previous
                            </ValidatedButton>
                        </NumberedStep>
                    </ProgressSteps>
                    {appId ? (
                        <div>
                            <div className="surfboard-output">
                                <p className="surfboard-output-label">Use this link to open your Board as a webpage</p>
                                <CodeSnippet className="surfboard-code-snippet my-dark-theme" type="single">{APP_URL}</CodeSnippet>
                            </div>
                            <div className="surfboard-output">
                                <p className="surfboard-output-label">Use the HTML snippet to embed your Board in your website</p>
                                <CodeSnippet className="surfboard-code-snippet my-dark-theme" light={false} type="multi">{APP_HTML}</CodeSnippet>
                            </div>
                        </div>
                    ): null}
                    
                </form>
            )}
        </Formik>
    );
};

export default CreatorView;
