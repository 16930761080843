import React, { useState, useRef, isValidElement } from 'react';
import { FormControl } from 'baseui/form-control';
import { FileUploader } from 'baseui/file-uploader';

const SketchUploader = props => {
  const {
    isPrimary,
    isMultiple, 
    label,
    accept,
    onUpload,
    onBlur, 
    touched, 
    error 
  } = props;

  const [isUploading, setIsUploading] = useState(false);
  const timeoutId = useRef(null);

  // const shouldShowError = !isValid;

  function reset() {
    setIsUploading(false);
    clearTimeout(timeoutId.current);
  }

  function handleUpload(rawFile) {
    const reader = new FileReader();
    rawFile.map(file => {
      const filename = file.name;
      reader.readAsText(file);
      reader.onload = event => {
      const fileContents = event.target.result;
      onUpload({
        content: fileContents, 
        name: filename,
        primary: isPrimary
      });
    }
    });

    setIsUploading(false);
  }

  function handleBlur(e) {
    onBlur(e);
  }

  return (
    <FormControl
        label={label}
        error={
          touched && error
          ? error
          : null
        }
    >
        <FileUploader
        onCancel={reset}
        accept={accept}
        onBlur={handleBlur}
        multiple={isMultiple}
        onDrop={(acceptedFiles, rejectedFiles) => { 
          handleUpload(acceptedFiles);
        }}
        progressMessage={
            isUploading ? `Uploading... hang tight.` : ''
        }
        />
    </FormControl>
  );
}

SketchUploader.defaultProps = {
  isPrimary: false
}

export default SketchUploader;